import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import SVGTranslation from "./SVG/translation-svg";
import SVGCopywriting from "./SVG/copywriting-svg";
import SVGCopycoaching from "./SVG/copycoaching-svg";

const Services = () => {
	return (
		<Container>
			<Row>
				<Col className="text-center mb-5">
					<h2 className="underline fs-2 fs-lg-1 ">Services</h2>
				</Col>
			</Row>
			<Row>
				<Col
					className="text-center position-relative px-md-5 px-md-5 pb-1 mb-7 mb-xl-0 pb-md-0 "
					xl={4}
				>
					<SVGCopycoaching className="mw-20 h-25 mb-md-3" />
					<h3 className="normal-font fw-bold fs-2">Ghostwriting</h3>
					<p className="text-start ">
						As your discreet ghostwriter, I will act as your personal silent
						storyteller and help you turn your ideas and experience into a
						compelling book that aligns perfectly with your brand and resonates
						with your audience. I will work closely with you to create a
						powerful narrative that leaves a lasting impact and establishes you
						as a thought leader and an expert in your field.
					</p>
					<Link
						className="cta-link pink-link top-110 start-0 ps-2 ps-md-5 position-absolute fw-bold"
						to="/services/ghostwriting"
					>
						{">"} Read More
					</Link>
				</Col>
				<Col
					className="text-center position-relative px-md-5 pb-4 mb-7 mb-xl-0 pb-md-0 
					"
					xl={4}
				>
					<SVGCopywriting className="mw-20 h-25 ms-4 mb-md-3" />
					<h3 className="normal-font fw-bold fs-2">Copywriting</h3>
					<p className="text-start pb-0 pb-xl-4 ">
						As a London-based copywriter, I can help you by using the written
						word to increase your sales. By listening to you and understanding
						your customers’ needs I can write website copy, brochure copy,
						blogs, advertising copy or articles that resonate with your readers.
						Compelling copy that tells your customers or readers exactly what
						they need to know to buy from you without unnecessary jargon is
						essential to prevent your customers from clicking away.
					</p>
					<Link
						className="cta-link pink-link top-110 start-0 ps-2 ps-md-5 position-absolute fw-bold"
						to="/services/copywriting"
					>
						{">"} Read More
					</Link>
				</Col>

				<Col
					className="text-center position-relative px-md-5 px-md-5 pb-3 mb-7 mb-xl-0 pb-md-0 "
					xl={4}
				>
					<SVGTranslation className="mw-20 h-25 mb-md-3" />
					<h3 className="normal-font fw-bold fs-2">Translation</h3>
					<p className="text-start ">
						As a fully qualified translator with 15 years of experience, I
						provide Italian into English translation, French into English
						translation and Spanish into English translation services for
						individual and corporate clients and agencies in London and
						worldwide. My specialist fields are financial, legal, marketing and
						pharmaceutical translations.
					</p>
					<Link
						className="pink-link top-110 start-0 ps-2 ps-md-5 position-absolute cta-link fw-bold"
						to="/services/translation"
					>
						{">"} Read More
					</Link>
				</Col>
			</Row>
		</Container>
	);
};

export default Services;
