import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";

const InfoPanel = ({ title, text, image, imgAlt }) => {
	return (
		<>
			<div Tag="section" className=" position-relative  ">
				<div
					style={{ zIndex: "-3" }}
					className="bg-light-background h-100 w-100 position-absolute"
				></div>
				<Container>
					<Row className=" py-5  px-md-3 align-items-center">
						<Col xl={6} className="pl-0 px-md-5 ps-md-3">
							<div>
								<Row className="align-items-center">
									<Col md={7} xl={12}>
										<h2
											className="pt-3 mb-md-5 mb-4 fs-2 fs-lg-1 underline"
											id="services-hero-title"
										>
											{title}
										</h2>
									</Col>
									<Col md={5} lg={5}>
										<div
											style={{ maxWidth: "12rem", height: "10rem" }}
											className="bg-background d-xl-none position-relative mb-5
								 mx-auto"
										>
											<div className="position-absolute top-10 end-10">
												<GatsbyImage
													style={{ width: "12rem", height: "10rem" }}
													image={image}
													alt={imgAlt}
												/>
											</div>
										</div>
									</Col>
								</Row>
							</div>
							<p className=" mb-4 mb-lg-6 mb-xl-4 fw-light">{text}</p>
							<Row className="">
								<Col className="py-3 py-md-0" md={6}>
									<Button
										className="cta-btn w-xl-80 w-100 py-md-3"
										variant="secondary"
										as={Link}
										to="/contact"
									>
										Enquire
									</Button>
								</Col>
								<Col md={6}>
									<Button
										className="cta-btn w-xl-80 w-100 py-md-3 white-button border-2 "
										as={Link}
										to="/about"
										variant="outline-secondary"
										style={{ backgroundColor: "white" }}
									>
										More About Me
									</Button>
								</Col>
							</Row>
						</Col>
						<Col className="text-center d-none d-xl-block" lg={6}>
							<div
								style={{ maxWidth: "26rem", height: "24rem" }}
								className="bg-background position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "26rem", height: "24rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default InfoPanel;
